export const useMemberReviews = (reviewId: string) => {
  const reviewsStore = useReviewsStore();

  const currentReview = computed(() => {
    return reviewsStore.reviews.find((review) => review.id === reviewId);
  });

  const reviewedOrder = computed(() => {
    if (!currentReview.value?.orderId) {
      return;
    }

    return reviewsStore.currentReviewedOrder(currentReview.value?.orderId);
  });

  const currentReviewedProduct = computed(() => {
    if (!currentReview.value?.productId) {
      return;
    }

    return reviewedOrder.value?.products[currentReview.value?.productId];
  });

  const isReportReview = computed(() => currentReview.value?.survey.isReport);

  const saveReviewAnswer = async (
    answer: string | number | boolean | string[],
    questionId: string
  ) => {
    if (!currentReview.value?.id) {
      return;
    }

    try {
      await reviewsStore.saveReviewAnswer({
        id: currentReview.value?.id,
        questionId: questionId,
        answer: answer,
      });
    } catch (error: any) {
      throw createError({
        statusMessage:
          error?.statusMessage || 'Error could not save the answer',
      });
    }
  };

  return {
    currentReview,
    reviewedOrder,
    currentReviewedProduct,
    isReportReview,
    saveReviewAnswer,
    reviews: reviewsStore.reviews,
    isLoadingReviews: reviewsStore.isLoading,
  };
};
